import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Founder from "./Founder"
import CTA from "./CTA";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden" >
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus Bookkeeping
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Who We Are
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus is a high end financial operations company. We specialize in
            creating efficient back office solutions for both businesses and
            individuals.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Our goal is to take the stress and burden of financial operations
            away from business owners so that they can focus on running and
            growing their business.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus is 100% Canadian owned and operated so you can trust that
            your financial data is in the right hands. By creating an efficient
            digital infrastructure and providing a dedicated account
            representative we assure a gold standard of service and
            accessibility.
          </p>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Our Core Values
          </span>
          <h3>Relentless Pursuit of Excellence</h3>
          <p>
            We strive to provide the most efficient, technologically advanced
            solutions available. Our dedicated representatives hold themselves
            accountable for every service provided. We pride ourselves on
            maintaining these values to ensure you are always receiving the
            highest quality service.
          </p>
          <h3>Security and Privacy</h3>
          <p>
            The security and privacy of your financial information is at the
            forefront of Certus’s values. Our infrastructure ensures that your
            information is always protected. We are 100% Canadian owned and
            operated with all work being completed by Certus employees. This
            gives business owners peace of mind that none of their information
            is being outsourced to a third party.
          </p>
          <h3>Act With Integrity</h3>
          <p>
            Overseeing financial operations requires the utmost integrity. We
            are always open and honest with our clients and work with them to
            create solutions that make them feel at ease. You can rest assured
            that with Certus, every penny will be accounted for.
          </p>
          <h3>Problem Solving</h3>
          <p>
            We understand that complex business operations can result in complex
            problems. We strive to consistently provide the most comprehensive
            solutions and will not rest until all problems are solved with
            effective, efficient processes. We don’t provide band-aid fixes that
            will rear their head at year end, we solve problems in real time to
            assure tax season is stress free.
          </p>
        </div>
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto py-4" style={{paddingBottom: "0px"}}>
          <h2>Why Choose Certus?</h2>
          <p>
            Choosing Certus is like hiring a CFO to run your company’s financial
            operations. Our digital infrastructure streamlines workflows and
            ensures there is no wasted time or extra burden on business owners
            or their employees. Not only do we take care of the work, but you
            can be assured that your company will always be up to date with any
            technological advances in the bookkeeping industry.
          </p>
          <p>
            Certus saves you the time and money associated with hiring and
            training one or multiple employees. Your dedicated Certus
            representative is a trained expert that will provide you with a
            1-on-1 relationship, making it feel like they work directly for you.
            Quite simply, Certus removes all of the stress and burden associated
            with your financial operations and gives you the ability to focus on
            what is most important, running your business.
          </p>
        </div>
        <Founder/>
      </div>
    </div>
  );
}
