import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const people = [
  {
    name: "Zachary Smith",
    role: "Founder & CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Zachary Smith created Certus to fulfill a niche that was lacking in today’s fast growing business sector. He saw the need for high quality, easy to access bookkeeping solutions for both businesses as well as individuals.  He sought to create the solution; full service financial operations with the highest level of commitment to the customer experience.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  // More people...
];

export default function Example() {
  return (
    <div
      className="relative   overflow-hidden"
      style={{ paddingTop: "0px" }}
    >
      <div
        className="relative px-4 sm:px-6 lg:px-8"
        style={{ paddingBottom: "0px" }}
      >
        <span
          className="block text-3xl mt-6 mb-0 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl pt-4"
         
        >
          Meet Our Founder
        </span>
        <div
          className="mx-auto px-4 sm:px-6 lg:px-4   items-center "
          style={{ paddingTop: "0px" }}
        >
          <div
            className=" prose prose-indigo prose-lg text-gray-500 mx-auto"
            style={{ paddingTop: "0px" }}
          >
            {people.map((person) => (
              <div
                key={person.name}
                className="sm:py-8"
                style={{ paddingBottom: "0px" }}
              >
                <div className="space-y-1 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0  ">
                  <div className="aspect-w-3 aspect-h-4 sm:aspect-w-4 sm:aspect-h-6" style={{marginTop: "0px", paddingBottom: "0px"}}>
                    <StaticImage
                      src="../../images/zach.jpg"
                      alt="A dinosaur"
                      placeholder="fixed"
                      layout="fullWidth"
                      height="250"
                      imgStyle={{ overflow: "hidden" , marginTop: "0px" }}
                      imgClassName="rounded-lg"
                      style={{ overflow: "hidden" , marginTop: "0px" }}
                      border="5px"
                      // You can optionally force an aspect ratio for the generated image
                      aspectRatio={1 / 1}
                      // This is a presentational image, so the alt should be an empty string
                      alt=""
                      // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <div className="sm:col-span-2 mt-0" style={{ paddingTop: "0px" }}>
                    <div className="space-y-4 mt-0">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3 style={{marginTop: "0px", marginBottom: "0px"}}>{person.name}</h3>
                        <p className="text-primary">{person.role}</p>
                      </div>
                      <div className="text-lg ">
                        <p className="text-gray-500">{person.bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
